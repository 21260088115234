import { Injectable } from '@angular/core';
import { Settings } from 'src/app/app.settings.model';

@Injectable()
export class AppSettings {

public settings = new Settings(
        'Fotovoltaica',
        'Fotovoltaica',
        {
            navbarIsFixed: true,
            footerIsFixed: true,
            sidebarIsFixed: true,
            skin:'combined',  //light , dark, blue, green, combined, purple, orange, brown, grey, pink
        },
        {
            googleMapsAPIKey: 'AIzaSyArAw6pxrBkxctXA4yj3zSN2RzndKjscyo',
            feature_googleMaps: true,
            googleAnalytics: '',
            feature_googleAnalytics: false,
            googleTagManager: '',
            feature_googleTagManager: false,
            feature_preview_dialog: false,
            protocol: 'https',
            host: 'app.energysequence.com',
            port: 443,
            calculatorEndpoint: 'v2/pv/',
            simplifiedEndpoint: 'v2/ssc/',
            loginEndpoint: 'v2/pvauth/',
            tosURL: 'https://e3ingenia.com/politica-de-privacidad/',
        },
        {
            id: '10',
            name: 'e3ingenia',
            email: 'info@e3ingenia.es',
            web: 'http://e3ingenia.com/',
            logo: 'assets/img/app/logo.png',
            instagram: '',
            twitter: '',
            youtube: '',
            linkedin:'https://www.linkedin.com/company/e3-ingenia-sl/about/',
            facebook: '',
            privacyPolicies: 'https://e3ingenia.com/aviso-legal/',
            backgroundPhoto: '/assets/img/app/background.jpg',
            buttonStyle: 'btn-outline-primary',
            fontFamily: '',
            navbarLogoDimension: '',
            showHeader: 1,
            showFooter: 1,
            welcome_text: 'Calcula tu instalación solar fotovoltaica',
            promotion_text: '', //Accede al autoconsumo ahorrándose hasta 70% en su factura de la luz
            telephone_required: 1,
            name_required: 1,
            cups_required: 0,
            allow_login: 1,
            inital_map_latitude: 36.4722177,
            inital_map_longitude: -6.1971901,
            investment_renting: 0,
            simplified_mode: false,
            home_costs: [50, 100, 200, 400, 1000],
            company_costs: [50, 100, 200, 400, 1000],
            feature_installation_costs: true,
            feature_cost_saving_percent: true
        }
    )
}

